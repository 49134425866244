.about-mobility {
  min-height: 48.87rem;
  position: relative;

  background-image: url("../../img/background/cars-bg.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  padding-top: 1rem;
  padding-bottom: 1rem;

  display: flex;
  align-items: center;


  .container {
    display: flex;
    flex-direction: column;
  }
}

.about-mobility__extra {
  background-image: url("../../img/background/dark-car-bg.jpg");

  .about-mobility__text {
    margin-right: auto;
    margin-left: 0;
    padding-bottom: 0;
    padding-top: 1.5rem;
    font-weight: 300;

    span{
      font-weight: 700;
    }

    @media screen and (max-width: 1024px){
      width: 100%;
      padding-top: 0;
    }
  }

  .about-mobility__text-row {
    width: 100%;
    margin-bottom: 2.5rem;
  }


}

.about-mobility__bg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: var(--tectonic-black-light);
  opacity: 0.8;
}


.about-mobility__text {
  width: 48.12%;
  color: var(--tectonic-white);
  font-size: 1.5rem;
  line-height: 1.5;
  font-weight: 300;
  z-index: 1;
  margin-left: auto;
  padding-bottom: 2.5rem;


  span{
    font-weight: 700;
  }

  @media screen and (max-width: 1024px){
    width: 100%;
  }
}

.about-mobility__text-row {
  width: 99%;
  margin-bottom: 1.5rem;
}

.about-mobility__text-row span {
  font-weight: 700;
}
