.keys{
  background-color: var(--tectonic-black-light);
  padding-top: 6.43rem;
  padding-bottom: 7.43rem;
}

.keys-row{
  display: flex;
  flex-direction: column;
}

.keys-title{
  font-size: 1.75rem;
  color: var(--tectonic-white);
  margin-bottom: 6rem;
  line-height: 1.5;
  text-align: center;
  width: 45.05%;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 600px){
    width: 100%;
  }
}

.keys-list{
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 1024px){
    justify-content: space-between;
  }
}

.keys-item{
  width: 21%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:not(:last-child){
    margin-right: 5.33%;
  }

  @media screen and (max-width: 1024px){
    width: 45%;

    &:not(:last-child){
      margin-right: 0;
      margin-bottom: 3rem;
    }
  }

  @media screen and (max-width: 600px){
    width: 100%;
  }
}


.keys-item__icon{
  position: relative;
  width: 5.62rem;
  margin-bottom: 1.18rem;

  img{
    max-width: 100%;
    max-height: 100%;
  }

  &:before{
    content: "";
    display: block;
    padding-top:80%;
  }
}

.keys-item__text{
  color: var(--tectonic-white);
  font-size: 1.5rem;
  line-height: 1.5;
  text-align: center;
}
