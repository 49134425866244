.info {
  .container {
    width: 100%;
  }
}

.info-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.info-item:nth-child(even) {
  .info-row {
    flex-direction: row-reverse;

    .info-column__info {
      padding-top: 4.8rem;
      padding-right: 4.8rem;
      padding-bottom: 1.81rem;
      padding-left: 5.93rem;

      @media(max-width: 1024px){
        padding-left: 2rem;
        padding-right: 2rem;
      }
    }

    @media(max-width: 1024px) {
      flex-direction: column;
    }
  }
}


.info-row {
  display: flex;
  align-items: flex-start;

  @media(max-width: 1024px) {
    flex-direction: column;
  }
}

.info-column {
  display: flex;
  flex-direction: column;
}

.info-column__image {
  width: 50%;
  align-self: stretch;

  @media(max-width: 1024px) {
    width: 100%;
  }
}

.info-column__info {
  width: 50%;
  color: var(--tectonic-black-light);

  padding-top: 2.62rem;
  padding-left: 3.93rem;
  padding-right: 5rem;
  padding-bottom: 1.81rem;

  @media(max-width: 1024px) {
    width: 100%;
    padding-right: 2rem;
    padding-left: 2rem;
  }

}

.info-column__image-img {
  position: relative;
  height: 100%;
  width: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:before {
    content: "";
    display: block;
    padding-top: 66.66%;
  }
}

.info-column__info-title {
  font-size: 4rem;
  font-weight: 900;
  line-height: 1.2;
  margin-bottom: 2rem;
}


.info-column__info-list {
  list-style: none;
  margin: 0;
  padding: 0;

  @media (max-width: 1024px) {
    margin-bottom: 1rem;
  }
}

.info-column__info-item {
  position: relative;

  font-size: 1.5rem;
  line-height: 1.5;
  font-weight: 300;

  span {
    font-weight: 700;
  }

  &:not(:last-child) {
    margin-bottom: 0.8rem;
  }

  &:first-child {
    margin-bottom: 1.5rem;
  }

  &:not(:first-child) {
    padding-left: 2.25rem;

    &:before {
      content: "";
      display: block;
      width: 0.37rem;
      height: 0.37rem;
      border-radius: 50%;
      position: absolute;
      top: 1rem;
      left: 1rem;
      background-color: var(--tectonic-black-light);
    }
  }
}

.info-column__info-hint {
  font-size: 1.5rem;
  line-height: 1.5;
  font-weight: 300;

  padding-top: 3.6rem;

  margin-top: auto;

  span {
    font-weight: 700;
  }
}
