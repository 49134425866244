.investments {
  padding-top: 4.62rem;
  padding-bottom: 5.12rem;
  background-color: var(--tectonic-black-light);
}

.investments-row {
  display: flex;
  flex-direction: column;
}


.investments-title {
  width: 62%;
  margin-left: auto;
  margin-right: auto;
  font-size: 2.25rem;
  line-height: 1.5;
  color: var(--tectonic-white);
  text-align: center;
  margin-bottom: 3.5rem;

  span {
    font-weight: 700;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}


.investments-info__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: flex-start;

  @media screen and (max-width: 1024px) {
    flex-wrap: wrap;
  }
}

.investments-info__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 13.4%;

  &:not(:last-child) {
    margin-right: 4.29rem;
  }

  @media screen and (max-width: 1024px) {
    width: 50%;

    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: 3rem;
    }
  }

  @media screen and (max-width: 600px) {
    width: 100%;
  }
}


.investments-info__item-icon {
  position: relative;
  width: 4.5rem;
  flex-shrink: 0;
  margin-bottom: 0.3rem;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  &:before {
    content: "";
    display: block;
    padding-top: 100%;
  }
}

.investments-info__item-text {
  font-size: 1.5rem;
  line-height: 1.5;
  color: var(--tectonic-white);
  font-weight: 700;
  text-align: center;
}
