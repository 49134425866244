.property {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  min-height: 44.68rem;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  padding-bottom: 4.5rem;
  padding-top: 1.5rem;

  @media screen and (max-width: 667px) {
    padding-top: 4.5rem;

    min-height: unset;
  }
}

.property-energy {
  background-image: url("../../img/background/energy.jpg");

  @media screen and (max-width: 1024px) {
    background-position: left center;
  }
}

.property-economy {
  position: relative;
  background-image: url("../../img/background/bottles.jpg");

  .container {
    z-index: 1;
  }
}

.property-mobility {
  background-image: url("../../img/background/car.jpg");
}

.property-innovative {
  position: relative;
  background-image: url("../../img/background/innovation.jpg");

  .container {
    z-index: 1;
  }

  .property-info__desc {
    width: 54%;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

}

.property-info {
  color: var(--tectonic-white);
  display: flex;
  flex-direction: column;
  width: 54.6%;
  margin-bottom: 2.25rem;

  @media(max-width: 1024px) {
    width: 100%;
  }
}

.property-info__title {
  font-size: 4.5rem;
  font-weight: 900;
  line-height: 1.37;

  margin-bottom: 0.5rem;

  text-transform: uppercase;

/*  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;*/

  @media(max-width: 1024px) {
    font-size: 3.5rem;
  }
}

.property-info__desc {
  width: 64%;
  font-size: 1.5rem;
  line-height: 1.5;

  @media(max-width: 1024px) {
    width: 100%;
  }

}

.property-button {
  appearance: none;
  background-color: transparent;
  border: 1px solid var(--tectonic-white);
  position: relative;

  display: inline-flex;

  border-radius: calc(18px + 0.125rem);

  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 1.31rem;
  padding-left: 1.31rem;

  color: var(--tectonic-white);

  transform: translate3d(0, 0, 0);

  align-self: flex-start;
  overflow: hidden;

  font-size: calc(15px + 0.1825rem);
  line-height: 1.22;

  cursor: pointer;

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    transform: translate(-100%);
    background-color: var(--tectonic-white);
    transition: all 400ms;

  }

  span {
    z-index: 2;
  }

  @media(any-hover: hover) {
    &:hover {
      color: var(--tectonic-green);

      &:before {
        transform: translate(0);
      }

    }
  }


}

.property-button__icon {
  position: relative;
  width: 0.5rem;
  margin-left: 0.75rem;

  align-self: center;

  &:before {
    content: "";
    display: block;
    padding-top: 160%;
  }

  svg {
    max-width: 100%;
    max-height: 100%;
  }
}


.content-right {
  .container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .property-info {
    align-items: flex-end;
    text-align: right;
  }

  .property-button {
    align-self: flex-end;
  }
}
