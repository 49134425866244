.colors {
  padding-top: 9.37rem;
  padding-bottom: 3rem;

  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("../../img/background/colors.jpg");
  background-color: var(--tectonic-creamy);

  @media screen and (max-width: 1024px) {
    padding-top: 3rem;

    background-image: linear-gradient(90deg, rgba(223, 223, 222, 1) 0%, rgba(223, 223, 223, 1) 50%, rgba(225, 225, 225, 1) 100%);
  }
}

.colors-text {
  width: 45%;
  margin-bottom: 3.93rem;
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  color: var(--tectonic-black-light);
  font-weight: 300;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}


.colors-text__row {
  line-height: 1.5;
  padding-left: 1.2rem;
  position: relative;

  span {
    font-weight: 700;
  }

  &:not(:last-child) {
    margin-bottom: 1.68rem;
  }

  &:before {
    content: "";
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: var(--tectonic-black);
    left: 0;
    top: 1rem;
  }

  @media screen and (min-width: 1921px) {
    br {
      display: none;
    }
  }

  @media screen and (max-width: 1024px) {
    br {
      display: none;
    }
  }
}

.colors-hint {
  line-height: 1.5;
  color: var(--tectonic-black-light);
  font-family: 'SF Pro Display', "Arial", "sans-serif";
  margin-bottom: 4.18rem;
}


.colors-logo {
  position: relative;
  width: 28.23%;
  min-width: 200px;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  &:before {
    content: "";
    display: block;
    padding-top: 18.30%;
  }
}
