.client{
  background-image: url("../../img/background/client.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 49.37rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 1rem;
  padding-bottom: 1rem;

  .container{
    width: 85.62%;
  }

}

.client-info{
  width: 49.7%;

  @media(max-width: 1024px){
    width: 68%;
  }

  @media(max-width: 450px){
    width: 90%;
  }
}
