.terms {
  padding-top: 12.312rem;
  padding-bottom: 9.375rem;
}

.terms-container {
  width: 52.08%;

  @media screen and (max-width: 1024px) {
    width: 91.66%;
  }
}

.terms-caption {
  font-size: 1.5rem;
  color: var(--tectonic-black);
  text-align: center;

  text-decoration: underline;

  margin-bottom: 1.5rem;
}

.terms-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.terms-item {
  margin-bottom: 3rem;
}

.terms-item__caption {
  font-size: 1rem;
  font-weight: 600;
  color: var(--tectonic-black);

  text-decoration: underline;

  margin-bottom: 1.5rem;
}

.terms-item__desc {
  font-size: 1rem;
  font-weight: 600;
  color: var(--tectonic-black);
}

.terms-item__desc-line {


  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
}


.terms-accept {
  display: flex;
  justify-content: flex-start;
}

.terms-accept__btn {
  border-radius: 1.25rem;
  background-color: transparent;

  text-decoration: none;

  border: 1px solid var(--tectonic-black-light);

  padding-top: 0.4rem;
  padding-right: 0.5rem;
  padding-bottom: 0.4rem;
  padding-left: 0.5rem;

  font-size: calc(15px + 0.1875rem);
  color: var(--tectonic-black-light);
  text-transform: uppercase;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  min-width: 8.125rem;
}
