.property-innovative__extra {
  padding-bottom: 8.5rem;
  padding-top: 11rem;
}

.infrastructure-city {
  padding-top: 5.06rem;
  padding-bottom: 5.93rem;
}

.infrastructure-city__row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 1024px){
    flex-direction: column-reverse;
  }
}

.infrastructure-city__text {
  width: 45.11%;
  font-size: 1.5rem;
  font-weight: 300;
  color: var(--tectonic-black-light);

  span {
    font-weight: 700;
  }

  @media screen and (max-width: 1024px){
   width: 100%;
  }
}


.infrastructure-city__text-row:not(:last-child) {
  margin-bottom: 1rem;
}

.infrastructure-city__text-column {
  margin-bottom: 0.87rem;
}

.infrastructure-city__text-column__row {
  position: relative;
  padding-left: 2.5rem;

  &:before {
    content: "";
    position: absolute;
    width: 5px;
    height: 5px;
    background-color: var(--tectonic-black);
    top: 1rem;
    left: 1rem;
    border-radius: 50%;
  }
}


.infrastructure-city__img {
  position: relative;
  width: 43.97%;

  img {
    width: 100%;
    height: 100%;
  }

  &:before {
    content: "";
    display: block;
    padding-top: 76.22%;
  }

  @media screen and (max-width: 1024px){
    margin-bottom: 1.5rem;
    min-width: 280px;
  }
}


.infrastructure-keys {
  padding-top: 5.43rem;
  padding-bottom: 10.43rem;

  .container {
    width: 67.81%;
  }
  .keys-item{
    width: 22.85%;

    &:not(:last-child){
      margin-right: 15.72% ;
    }

    @media screen and (max-width: 1024px){
      width: 100%;

      &:not(:last-child){
        margin-right: 0 ;
      }
    }
  }

  .keys-title{
    margin-bottom: 8.7rem;
    width: 100%;
  }

}


