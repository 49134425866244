.footer {
  position: relative;

  padding-top: 3.75rem;
  padding-bottom: 3.75rem;

  .container {
    //padding-top: 4.12rem;
    //padding-bottom: 3.68rem;

    display: flex;
    justify-content: center;
    align-items: flex-start;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }
  }

  .nav {
    margin-bottom: 1.31rem;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  .nav-list {
    @media screen and (max-width: 1024px) {
      flex-direction: column;
      align-items: flex-start;

      width: 100%;
    }
  }

  .nav-item:nth-child(n) {
    @media screen and (max-width: 1024px) {
      margin-right: 0;

      width: 100%;
    }
  }

  .nav-link {
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  .footer-nav__link {
    @media screen and (max-width: 1024px) {
      width: 100%;
      &:not(:last-child) {
        margin-right: 0;
      }
    }
  }

  .nav-dropdown__link {
    @media screen and (max-width: 1024px) {
      justify-content: flex-start;
    }
  }
}

.footer-after-login {
  .footer-logo {
    @media screen and (min-width: 1025px) and (max-width: 1200px) {
      width: 17.45%;
    }
  }
}

.footer-logo {
  position: absolute;
  top: 3.75rem;
  left: 4.17%;

  display: inline-flex;

  width: 20.45%;
  flex-shrink: 0;

  min-width: 15rem;

  svg {
    max-width: 100%;
    max-height: 100%;
  }

  &:before {
    content: "";
    display: block;
    padding-top: 18.33%;
  }

  @media screen and (max-width: 1024px) {
    position: relative;
    top: auto;
    left: auto;

    margin-bottom: 2rem;
  }
}


.footer-nav {
  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;

  //padding-right: 25%;
  //padding-left: 25%;

  width: 67.42%;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}

.footer-nav__copyright {
  font-size: 0.87rem;
  line-height: 1.5;
  color: var(--tectonic-gray);

  margin-bottom: 0.875rem;

  @media screen and (max-width: 1024px) {
    margin-right: auto;
  }

  @media screen and (max-width: 667px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.copyright-text {
  margin-right: 1.5rem;

  @media screen and (max-width: 667px) {
    margin-right: 0;
    margin-bottom: 0.875rem;
  }
}

.copyright-link {
  text-decoration: none;
  color: var(--tectonic-gray);
}

.footer-nav__info {
  font-size: 0.875rem;
  font-weight: 700;
  color: var(--tectonic-gray-lighter);

  width: 80%;

  margin-right: auto;
  margin-left: auto;

  text-align: center;

  @media screen and (max-width: 1024px) {
    width: 100%;

    text-align: left;

    margin-bottom: 1.31rem;
  }
}

.footer-author {
  position: absolute;
  top: 3.75rem;
  right: 4.17%;

  width: 14.437rem;
  min-width: 165px;
  flex-shrink: 0;

  &::before {
    content: '';
    display: block;
    padding-top: 40%;
  }

  img {
    max-height: 100%;
    max-width: 100%;
  }

  .img-container {
    justify-content: flex-end;
  }

  @media screen and (max-width: 1024px) {
    position: relative;
    top: auto;
    right: auto;

    .img-container {
      justify-content: flex-start;
    }

    &::before {
      padding-top: 52%;
    }
  }
}

.footer-social {
  display: flex;
  align-items: flex-start;

  @media screen and (max-width: 1024px) {
    margin-bottom: 1.5rem;
    align-items: center;
  }
}

.footer-social__icon {
  position: relative;
  width: 1rem;
  flex-shrink: 0;

  display: inline-flex;

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  &:not(:last-child) {
    margin-right: 1.5rem;
  }

  .img-container {
    align-items: flex-end;
  }

  svg {
    max-height: 100%;
    max-width: 100%;
    fill: var(--tectonic-black-light);
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    left: -0.5rem;
    right: -0.5rem;
    top: -0.5rem;
    bottom: -0.5rem;
  }

  @media screen and (max-width: 1024px) {
    min-width: 16px;
    min-height: 16px;

    svg {
      width: 17px;
      height: auto;
    }
  }

  @media(any-hover: hover) {
    &:hover {

      svg {
        transition: 400ms;
        fill: var(--tectonic-green);
      }
    }

  }
}
