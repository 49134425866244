.about-mobility__city{
  background-image: url("../../img/background/city-bg.jpg");


  .about-mobility__text-row{
    width: 100%;
    margin-bottom: 2.5rem;
  }

  .about-mobility__text{
    padding-bottom: 1.5rem;
  }

  .about-mobility__city-bg{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--tectonic-black-light);
    opacity: 0.7;
  }
}
