.bio-plastic {
  padding-top: 5.93rem;
  padding-bottom: 5.93rem;

  .container{
    width: 78.59%;
  }
}

.bio-plastic__row {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media  screen and (max-width: 1024px){
    flex-direction: column;
  }
}

.bio-plastic__icon {
  position: relative;
  width: 27.17%;
  min-width: 200px;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  &:before {
    content: "";
    display: block;
    padding-top: 128.29%;
  }

  @media  screen and (max-width: 1024px){
    margin-bottom: 1.5rem;
  }
}

.bio-plastic__text {
  width: 63.68%;
  color: var(--tectonic-black-light);
  font-size: 1.75rem;
  display: flex;
  flex-direction: column;
  padding-bottom: 3.75rem;

  @media  screen and (max-width: 1024px){
    width: 100%;
  }
}

.bio-plastic__text-row{
  line-height: 1.5;

  span{
    font-weight: 700;
  }

  &:not(:last-child){
    margin-bottom: 1.5rem;
  }


}

