.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;

  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 9.37rem;
  transition: 150ms;

  background-color: transparent;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @media screen and (max-width: 1024px) {
    position: absolute;
  }
}

.header-color__black {

  .logo {
    fill: var(--tectonic-black);
  }

  .nav-link {
    color: var(--tectonic-black-light);
  }

  .nav-link--active {
    color: var(--tectonic-green);
  }

  .nav-link__icon svg {
    fill: var(--tectonic-black-light);
  }
}

.header.active {
  transform: translateY(-100%);
}

.header-logo {
  display: inline-flex;
  position: relative;
  width: 26.64%;
  flex-shrink: 0;
  transition: 200ms;
  min-width: 150px;

  &:before {
    content: "";
    display: block;
    padding-top: 18.33%;
  }

  .img-container {
    justify-content: flex-start;
  }

  svg {
    max-width: 100%;
    max-height: 100%;
  }
}



.header .nav.active {
  transform: rotate(0);

  @media(max-width: 480px) {
    width: 100%;
  }

}

.menu-burger {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0;

  position: relative;

  height: 3rem;
  width: 4rem;

  flex-shrink: 0;

  align-items: center;
  justify-content: center;

  cursor: pointer;
  transition: all .2s ease-in-out;
  overflow: hidden;
  display: none;
  z-index: 21;

  @media(max-width: 1024px) {
    position: relative;
    z-index: 21;

    display: flex;
  }
}

.menu-burger.active {

  .menu-burger__row {
    transform: translateX(-50px);

    &:after {
      transform: rotate(45deg) translate(35px, -35px);
    }

    &:before {
      transform: rotate(-45deg) translate(35px, 35px);
    }
  }

}

.menu-burger__row {
  width: 3rem;
  height: 0.4rem;

  display: inline-flex;

  background-color: var(--tectonic-green);
  border-radius: 0.5rem;
  transition: all .2s ease-in-out;

  &:before {
    content: "";
    position: absolute;
    width: 3rem;
    height: 0.4rem;
    background-color: var(--tectonic-green);
    border-radius: 0.5rem;
    transition: all .2s ease-in-out;
    transform: translateY(7px);

  }

  &:after {
    content: "";
    position: absolute;
    width: 3rem;
    height: 0.4rem;
    background-color: var(--tectonic-green);
    border-radius: 0.5rem;
    transition: all .2s ease-in-out;
    transform: translateY(-7px);

  }
}

.header {
  .nav {
    @media(max-width: 1024px) {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 20;
      width: 50%;
      transform-origin: right bottom;
      transform: rotate(90deg);
      transition: 300ms;
      background-color: var(--tectonic-white);
    }
  }

  .nav-list {
    @media(max-width: 1024px) {
      height: 100%;
      width: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .nav-item {
    @media(max-width: 1024px) {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  .nav-item:not(:last-child) {
    @media(max-width: 1024px) {
      margin-right: 0;
    }
  }

  .nav-link {
    @media(max-width: 1024px) {
      display: flex;
      justify-content: center;

      padding-right: 0.5rem;
      padding-left: 0.5rem;

      text-align: center;

      width: 100%;

      font-size: 1.85rem;
      color: var(--tectonic-black-light);
    }
  }
}
