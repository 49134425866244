.property-economy__extra {
  padding-bottom: 8rem;
  padding-top: 11.37rem;

  @media screen and (max-width: 1024px) {
    .property-info__desc {
      width: 100%;
    }
  }
}

.recycle {
  padding-top: 2.87rem;
  padding-bottom: 5.37rem;
}

.recycle-row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 1024px) {
    flex-direction: column-reverse;
  }
}

.recycle-text {
  color: var(--tectonic-black-light);
  font-size: 1.5rem;
  font-weight: 300;
  width: 45.11%;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}

.recycle-text__row {
  span {
    font-weight: 700;
  }

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  &:last-child {
    margin-top: 2.3rem;
  }
}

.recycle-icon {
  position: relative;
  width: 49.37%;

  img {
    width: 100%;
    height: 100%;
  }

  &:before {
    content: "";
    display: block;
    padding-top: 36.24%;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    margin-bottom: 1.5rem;
  }
}
