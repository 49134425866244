.water{
  position: relative;
  background-image: url("../../img/background/water.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 40.18rem;

  display: flex;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;

  .container{
    display: flex;
    flex-direction: column;
  }
}

.water-bg{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--tectonic-black);
  opacity: 0.6;
}

.water-text{
  font-size: 1.75rem;
  color: var(--tectonic-white);
  z-index: 1;
  width: 54.6%;
  font-weight: 300;

  @media  screen and (max-width: 1024px){
    width: 100%;
  }
}

.water-text__row{
  line-height: 1.5;

  span{
    font-weight: 700;
  }

  &:not(:last-child){
  margin-bottom: 1.5rem;
}
}
