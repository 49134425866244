.changes {
  min-height: 100vh;
  background-color: var(--tectonic-black-light);

  padding-top: 12.312rem;
  padding-bottom: 3rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.changes-row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media(max-width: 1024px) {
    flex-direction: column;
  }
}

.changes-column {
  display: flex;
  flex-direction: column;
  color: var(--tectonic-white);
}

.changes-column__info {
  width: 41.85%;

  @media(max-width: 1024px) {
    width: 100%;
    margin-bottom: 3rem;
  }
}

.changes-column__title {
  text-transform: uppercase;
  font-size: 6.25rem;
  font-weight: 900;
  margin-bottom: 0.87rem;
  line-height: 1;

  @media(max-width: 400px) {
    font-size: 4rem;
  }
}

.changes-column__capture {
  font-size: 2.62rem;
  font-weight: 300;
  margin-bottom: 1.56rem;
}

.changes-column__par {
  font-size: 1.68rem;
  font-weight: 900;
  margin-bottom: 2.7rem;
}

.changes-column__desc {
  width: 96%;
  font-size: 1.37rem;
  font-weight: 300;
  line-height: 1.5;
  margin-bottom: 3.87rem;

  div:not(:last-of-type) {
    margin-bottom: 1.31rem;
  }

  span {
    font-weight: 700;
  }
}

.changes-column__button {
  position: relative;

  display: inline-flex;

  border: 1px solid var(--tectonic-white);

  border-radius: 1.25rem;

  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 1.68rem;
  padding-left: 1.68rem;

  align-self: flex-start;

  font-size: calc(15px + 0.1825rem);
  text-decoration: none;
  line-height: 1.2;
  color: var(--tectonic-white);

  transform: translate3d(0, 0, 0);

  cursor: pointer;
  overflow: hidden;

  span {
    z-index: 2;
  }


  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    transform: translateX(-100%);
    border-radius: 1.25rem;
    background-color: var(--tectonic-white);
    transition: all 400ms;
  }

@media (any-hover: hover){
  &:hover {
    color: var(--tectonic-green);

    &:before {
      transform: translateX(0);
    }
  }
}

}

.changes-column__img {
  width: 52.38%;
  min-width: 25rem;

  @media (max-width: 1024px) {
    align-self: flex-start;
    width: 100%;
  }
}

.changes-column__img-icon {
  position: relative;
  width: 100%;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  &::before {
    content: "";
    display: block;
    padding-top: 49.78%;
  }

  @media (max-width: 1024px) {
    .img-container {
      justify-content: flex-start;
    }
  ;
  }
}

.property-linear {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(270deg, #0C1D28 0%, rgba(12, 29, 40, 0) 100%);
}
