.economy-mobility{
  padding-top: 6.56rem;
  padding-bottom: 5.56rem;

  background-color: var(--tectonic-black-light);
}

.economy-mobility__row{
  display: flex;
  flex-direction: column;
}

.economy-mobility__text{
  font-size: 1.5rem;
  line-height: 1.5;
  color: var(--tectonic-white);
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 69.76%;
  margin-bottom: 6rem;
  font-weight: 300;

  span{
    font-weight: 700;
  }

  @media screen and (max-width: 1024px){
    width: 100%;
  }
}

.economy-mobility__text-row{
  margin-bottom: 2.2rem;
}


.economy-mobility__icons-list{
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  @media screen and (max-width: 1024px){
    justify-content: space-between;
  }
}

.economy-mobility__icons-item{
  width: 18.9%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:not(:last-child){
    margin-right: 1.37%;
  }

  @media screen and (max-width: 1024px){
    width: 45%;
    margin-bottom: 1.5rem;

    &:not(:last-child){
      margin-right: 0;
    }
  }

  @media screen and (max-width: 1024px){
    width: 100%;
    margin-bottom: 0;

    &:not(:last-child){
      margin-bottom: 1.5rem;
    }
  }
}

.economy-mobility__icons-item__icon{
  position: relative;
  width: 5.21rem;
  margin-bottom: 1.25rem;

  img{
    max-width: 100%;
    max-height: 100%;
  }

  &:before{
    content: "";
    display: block;
    padding-top: 83.89%;
  }
}

.economy-mobility__icons-item__text{
  font-size: 1.5rem;
  line-height: 1.5;
  color: var(--tectonic-white);
  text-align: center;
}
