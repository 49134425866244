.mobility-skeleton{
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.mobility-skeleton__content{
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1024px){
    flex-direction: column-reverse;
  }
}

.mobility-skeleton__text{
  width: 41%;
  font-size: 1.5rem;
  line-height: 1.5;
  color: var(--tectonic-black-light);
  font-weight: 300;

  span{
    font-weight: 700;
  }

  @media screen and (max-width: 1024px){
    width: 100%;
    text-align: center;
  }
}

.mobility-skeleton__img{
  width: 59%;
  position: relative;

  img{
    width: 100%;
    height: 100%;
  }

  &:before{
    content: "";
    display: block;
    padding-top: 50.38%;
  }

  @media screen and (max-width: 1024px){
    width: 100%;
    margin-bottom: 1.5rem;
  }
}
