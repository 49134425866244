.contact {
  padding-top: 9.8rem;
  padding-bottom: 17.87rem;

  @media screen and (max-width: 992px) {
    padding-bottom: 4rem;
  }
}

.contact-container {

}

.contact-row {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 992px) {
    flex-direction: column;
  }
}

.contact-column {
  flex-shrink: 0;
}

.contact-column--form {
  width: 35.8%;

  padding-top: 8rem;

  @media screen and (max-width: 992px) {
    width: 100%;

    padding-top: 0;

    margin-bottom: 1.5rem;
  }
}

.contact-column--bg {
  width: 43.63%;

  margin-right: 6.3%;

  @media screen and (max-width: 992px) {
    width: 60%;

    margin-right: auto;
    margin-left: auto;

    //order: -1;
  }
}

.contact-form {

}

.contact-form__line {
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  @media screen and (max-width: 992px) {
    &:last-child {
      justify-content: center;
    }
  }

  @media screen and (max-width: 667px) {
    flex-direction: column;
  }
}

.contact-form__label {
  width: calc(50% - 0.25rem);

  @media screen and (max-width: 667px) {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
}

.contact-form__label:only-child {
  width: 100%;
}

.contact-form__input {
  width: 100%;
}

.contact-form__textarea {
  height: 11.25rem;
  width: 100%;

  display: block;

  resize: none;
}

.contact-bg {
  position: relative;

  &::before {
    content: "";
    display: block;
    padding-top: 68.09%;
  }

  img {
    max-height: 100%;
    max-width: 100%;
  }
}
