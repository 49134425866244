.solar {
  padding-top: 1.68rem;
  padding-bottom: 7.5rem;

  .container {
    width: 94.32%;
    //margin-right: 1.81rem;
  }
}

.property-energy__extra {
  padding-bottom: 2.7rem;
  padding-top: 11rem;
}

.solar-row {
  display: flex;
  align-items: flex-start;

  @media screen and (max-width: 1024px) {
    flex-direction: column-reverse;
  }
}

.solar-column {
  display: flex;
  flex-direction: column;
}

.solar-column--info {
  width: 46.54%;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}

.solar-column--icon {
  width: 53.45%;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}

.solar-column__icon-img {
  position: relative;
  width: 100%;

  img {
    width: 100%;
    height: 100%;
  }

  &:before {
    content: "";
    display: block;
    padding-top: 47%;
  }
}

.solar-column__info {
  font-size: 1.5rem;
  line-height: 1.5;
  color: var(--tectonic-black-light);
  padding-top: 2.4rem;
  margin-bottom: 1.3rem;
}

.solar-column__title {
  margin-bottom: 2.31rem;

  span {
    font-weight: 700;
  }
}

.solar-column__desc {
  padding-left: 2rem;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  span {
    font-weight: 700;
  }
}


.solar-column__properties {
  font-size: 1.5rem;
  color: var(--tectonic-black-light);
}

.solar-column__properties-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.solar-column__properties {
  width: 89%;
}

.solar-column__properties-title {
  margin-bottom: 1.6rem;
}

.solar-column__properties-item {
  padding-left: 2.2rem;
  position: relative;

  span {
    font-weight: 700;
  }

  &:before {
    content: "";
    position: absolute;
    width: 0.31rem;
    height: 0.31rem;
    border-radius: 50%;
    background-color: var(--tectonic-black);
    left: 0.68rem;
    top: 0.93rem;
  }
}
