.timing {
  background-color: var(--tectonic-black-light);
  padding-top: 6.68rem;
  padding-bottom: 5rem;
}

.timing-row {
  display: flex;
  justify-content: space-between;

  @media(max-width: 1024px) {
    flex-direction: column;
  }
}

.timing-column {
  display: flex;
  flex-direction: column;
  color: var(--tectonic-white);
}

.timing-column__info {
  width: 40.85%;

  @media(max-width: 1024px) {
    width: 100%;
    margin-bottom: 3rem;
  }
}

.timing-title {
  font-size: 4rem;
  line-height: 1.2;
  font-weight: 900;
  margin-bottom: 4.12rem;
}

.timing-list {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 5.62rem;
}

.timing-item {
  font-size: 1.5rem;
  line-height: 1.5;
  font-weight: 700;
  position: relative;
  padding-left: 2.25rem;

  &:before {
    content: "";
    display: block;
    width: 0.37rem;
    height: 0.37rem;
    border-radius: 50%;
    position: absolute;
    top: 0.75rem;
    left: 0.87rem;
    background-color: var(--tectonic-white);
  }

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.timing-logo {
  position: relative;
  width: 72.04%;
  flex-shrink: 0;
  margin-left: 1.1rem;

  svg {
    max-width: 100%;
    max-height: 100%;
  }

  &:before {
    content: "";
    display: block;
    padding-top: 18.33%;
  }

  .img-container {
    justify-content: flex-start;
  }

  @media(max-width: 1024px) {
    align-self: flex-start;
    margin-left: 0;
  }
}

.timing-column__statistic {
  width: 47.38%;
  margin-top: 1.5rem;

  @media(max-width: 1024px) {
    width: 100%;
    margin-top: 0;
  }
}


.timing-column__statistic-img {
  position: relative;
  width: 100%;

  img {
    width: 100%;
    height: 100%;
  }

  &:before {
    content: "";
    display: block;
    padding-top: 81.77%;
  }
}
