.our-team {
  padding-top: 5.25rem;
  padding-bottom: 9.18rem;
  background-color: var(--tectonic-black-light);
}

.our-team__title {
  font-size: 4rem;
  line-height: 1.2;
  font-weight: 900;
  margin-bottom: 7.06rem;
  color: var(--tectonic-white);

  @media(max-width: 1024px) {
    margin-bottom: 3rem;
  }
}

.our-team__list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.our-team_item:nth-child(even) {
  .our-team_row {
    flex-direction: row-reverse;


    @media (max-width: 1024px) {
      flex-direction: column;
    }
  }

  .our-team_column-person__info {
    flex-direction: row-reverse;

    @media(max-width: 1024px) {
      flex-direction: column;
    }
  }

  .our-team_column:before {
    content: none;
  }

  .our-team_column:last-child:after {
    content: "";
    position: absolute;
    width: 2px;
    background-color: var(--tectonic-green);
    top: 0;
    bottom: 0;
    left: 114.5%;

    @media(max-width: 1024px) {
      content: none;
    }

  }
}

.our-team_item:not(:last-child) {
  margin-bottom: 10.5rem;

  @media(max-width: 1024px) {
    margin-bottom: 5rem;
  }
}

.our-team_row {
  display: flex;
  justify-content: space-between;

  @media(max-width: 1024px) {
    flex-direction: column;

  }
}

.our-team_column {
  display: flex;
  flex-direction: column;
  position: relative;

  @media(max-width: 1024px) {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }

  &:first-child {
    &:before {
      content: "";
      position: absolute;
      width: 2px;
      background-color: var(--tectonic-green);
      top: 0;
      bottom: 0;
      left: 107%;

      @media(max-width: 1024px) {
        display: none;
      }
    }
  }
}

.our-team_column-person {
  width: 60.85%;

  @media(max-width: 1024px) {
    width: 100%;
  }
}

.our-team_column-info {
  width: 29.8%;

  @media (max-width: 1024px) {
    width: 100%
  }
}

.our-team_column-person__info-image {
  position: relative;
  width: 25.23%;
  background-color: var(--tectonic-white);
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid var(--tectonic-green);
  flex-shrink: 0;
  min-width: 12.5rem;

  &:before {
    content: "";
    display: block;
    padding-top: 100%;
  }

  img {
    max-height: 100%;
    max-width: 100%;
    //width: 100%;
    //height: 100%;
    //object-fit: cover;
  }

  .img-container {
    padding-top: 1rem;
  }

  @media(max-width: 1024px) {
    margin-bottom: 1rem;
  }

}


.our-team_column-person__info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  align-items: flex-start;

  @media(max-width: 1024px) {
    flex-direction: column;
  }
}

.our-team_column-person__info-text {
  padding-right: 2rem;
  color: var(--tectonic-white);
  width: 72.79%;

  @media(max-width: 1024px) {
    width: 100%;
  }
}

.our-team_column-person__info-text__title {
  font-size: 2.25rem;
  color: var(--tectonic-green);
  line-height: 1.2;
  font-weight: 900;
  margin-bottom: 0.93rem;
}

.our-team_column-person__info-text__desc {
  font-size: 1.5rem;
  line-height: 1.5;
  font-weight: 300;
}

.our-team_column-person__hint {
  font-size: 1.5rem;
  color: var(--tectonic-white);

}

.our-team_column-info__title {
  font-size: 2.25rem;
  line-height: 1.5;
  color: var(--tectonic-green);
  font-weight: 900;
  margin-bottom: 1.06rem;

  div {
    font-size: 1.5rem;
  }
}

.our-team_column-info__list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.our-team_column-info__item {
  font-size: 1.5rem;
  line-height: 1.5;
  color: var(--tectonic-white);
  position: relative;
  padding-left: 2.31rem;

  &:before {
    content: "";
    position: absolute;
    left: 0.93rem;
    top: 0.75rem;

    width: 0.31rem;
    height: 0.31rem;
    border-radius: 50%;

    flex-shrink: 0;

    background-color: var(--tectonic-white);
  }
}
