.mobility-hint{
  background-image: url("../../img/background/car-row.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  padding-top: 1rem;
  padding-bottom: 1rem;

  position: relative;

  display: flex;
  align-items: center;

  min-height: 31.12rem;

  .container{
    display: flex;
    flex-direction: column;
  }
}

.mobility-hint__text{
  font-size: 1.5rem;
  line-height: 1.5;
  color: var(--tectonic-white);
  font-weight: 300;
  margin-left: auto;
  z-index: 1;

  width: 48.12%;

  span{
    font-weight: 700;
  }

  @media screen and (max-width: 1024px){
    width: 100%;
  }
}


.mobility-hint__bg{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: var(--tectonic-black-light);
  opacity: 0.7;
}

.mobility-hint__text-row{
  margin-bottom: 2.3rem;
}
