.smart-grid {
  padding-top: 9rem;
  padding-bottom: 12rem;

}

.smart-grid__row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 1024px) {
    flex-direction: column-reverse;
  }
}

.smart-grid__text {
  width: 42.04%;
  font-size: 1.5rem;
  line-height: 1.5;
  color: var(--tectonic-black-light);
  font-weight: 300;

  span {
    font-weight: 700;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}

.smart-grid__text-row:not(:last-child) {
  margin-bottom: 1.5rem;
}


.smart-grid__image {
  position: relative;
  width: 52.04%;

  img {
    width: 100%;
    height: 100%;
  }

  &:before {
    content: "";
    display: block;
    padding-top: 52.07%;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    margin-bottom: 3rem;
  }
}
