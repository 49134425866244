.why-us {
  padding-top: 4.06rem;
  padding-bottom: 4.06rem;
}

.why-us__row {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media(max-width: 1024px) {
    flex-direction: column;
    width: 100%;
  }
}

.why-us__title {
  font-size: 4rem;
  line-height: 1.2;
  font-weight: 900;
  color: var(--tectonic-black-light);
  width: 33.04%;

  @media(max-width: 1280px) {
    width: 40%;
  }

  @media(max-width: 1024px) {
    width: 100%;
    margin-bottom: 1.5rem;

    br {
      display: block;
    }
  }
}

.why-us__info {
  width: 47.27%;

  margin-right: 11.46%;

  @media(max-width: 1024px) {
    width: 100%;

    margin-right: 0;
  }
}

.why-us__info-row {
  line-height: 1.5;
  font-size: 1.5rem;
  font-weight: 350;
  color: var(--tectonic-black-light);

  margin-bottom: 0.5rem;

  span {
    font-weight: 700;
  }
}

.why-us__info-row__bold {
  font-size: 1.5rem;
  line-height: 1.5;
  font-weight: 700;
  margin-top: 1.5rem;
}
