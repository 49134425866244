.fund {
  padding-top: 5.1rem;
  padding-bottom: 7.68rem;
}

//1389px
.fund-container {
  width: 79.59%;

  @media screen and (max-width: 1024px) {
    width: 91.66%;
  }
}

.fund-caption {
  font-size: 4rem;
  font-weight: 900;
  color: var(--tectonic-black-light);

  margin-bottom: 2.62rem;
}

.fund-row {
  display: flex;

  @media screen and (max-width: 992px) {
    flex-direction: column;
  }
}

.fund-column {
  flex-shrink: 0;
}

.fund-column--first {
  width: 37.8%;

  @media screen and (max-width: 992px) {
    position: relative;
    width: 100%;

    padding-bottom: 3rem;

    &::after {
      content: '';
      position: absolute;
      right: 25%;
      bottom: 0;
      left: 0;
      height: 1px;
      background-color: var(--tectonic-green);
    }
  }

  @media screen and (max-width: 480px) {
    &::after {
      content: none;
    }

    padding-bottom: 0;
  }
}

.fund-column--first .fund-list::after {
  content: '';
  position: absolute;
  top: 0.5rem;
  right: 0;
  bottom: 2.5rem;
  width: 1px;
  background-color: var(--tectonic-green);

  @media screen and (max-width: 992px) {
    content: none;
  }
}

.fund-column--first .fund-item__name {
  width: 39.01%;

  padding-right: 0.5rem;

  @media screen and (max-width: 992px) {
    width: 50%;
  }

  @media screen and (max-width: 480px) {
    width: auto;
  }
}

.fund-column--first .fund-item__value {

}

.fund-column--second {
  margin-left: 7.71%;

  flex-grow: 1;

  @media screen and (max-width: 992px) {
    margin-left: 0;

    padding-top: 3rem;
  }
}

.fund-column--second .fund-item__name {
  width: 42%;

  @media screen and (max-width: 992px) {
    width: 50%;
  }

  @media screen and (max-width: 480px) {
    width: auto;
  }
}

.fund-list {
  position: relative;

  margin: 0;
  padding: 0;
  list-style: none;

  padding-right: 1.75rem;

  @media screen and (max-width: 992px) {
    padding-right: 0;
  }
}

.fund-item {
  display: flex;

  font-size: 1.5rem;
  color: var(--tectonic-black-light);

  &:not(:last-child) {
    margin-bottom: 2.3rem;
  }

  @media screen and (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.fund-item__name {
  font-weight: 700;

  @media screen and (max-width: 480px) {
    position: relative;

    display: inline-flex;

    font-weight: 900;

    padding-bottom: 0.55rem;
    margin-bottom: 0.55rem;

    &::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 1px;
      background-color: var(--tectonic-green);
    }
  }
}

.fund-item__value {
  font-weight: 400;

  line-height: 1.5;
}

.fund-item__list {
  margin: 0;
  padding: 0;
  list-style-position: inside;
  list-style-type: disc;
}
