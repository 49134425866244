.eco-city{
  padding-bottom: 9.7rem;

  .smart-grid__image{
    width: 52.89%;

    &:before{
      padding-top: 60.79%;
    }

    @media screen and (max-width: 1024px){
      width: 100%;
    }
  }


}
