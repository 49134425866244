.confirm-popup {
  position: relative;
  z-index: 1;

  margin: auto;

  display: flex;
  flex-direction: column;

  padding: 3.125rem;

  width: 46.87%;
  min-width: 56.25rem;

  background-color: var(--tectonic-white);
  border-radius: 1.875rem;
  border: 1px solid rgba(0, 0, 0, 0.2);

  @media screen and (max-width: 768px) {
    min-width: unset;

    width: 90%;
  }
}

.confirm-popup__info {
  font-size: 1.125rem;
  line-height: 1.5;
  color: var(--tectonic-black);

  margin-bottom: 1.5rem;
}

.confirm-popup__accept {
  display: flex;
  justify-content: center;
}

.confirm-popup__accept-btn {
  appearance: none;
  border: none;
  background-color: transparent;

  text-transform: uppercase;
  font-weight: 900;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  min-width: 6rem;

  padding-right: 0.875rem;
  padding-left: 0.875rem;
}
