.founders {
  background-color: var(--tectonic-black-light);
  min-height: 35.87rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 1rem;
  padding-bottom: 1rem;

  .container {
    display: flex;
    flex-direction: column;
  }
}

.founders-citate {
  position: relative;

  background-image: url("../../img/background/citate.jpg");
  background-position: center center;
  background-size: cover;

  min-height: 32.81rem;

  .founders-citate__bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(16, 25, 35, 0.8)
  }

  .founders-text {
    z-index: 1;
    width: 47.54%;

    @media (max-width: 667px) {
      width: 90%;
    }
  }

  @media screen and (max-width: 667px) {
    min-height: unset;

    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }
}

.founders-citate--end {
  order: 1;
}

.founders-text {
  color: var(--tectonic-white);
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  text-align: center;
  width: 56.54%;

  @media(max-width: 1024px) {
    width: 100%;
  }
}

.founders-desc {
  font-size: 1.75rem;
  margin-bottom: 2.62rem;
}

.founders-info {
  font-size: 1.5rem;

  div {
    font-weight: 700;
  }
}
