.process {
  min-height: 100vh;
  background-color: var(--tectonic-black-light);

  padding-top: 12.312rem;
  padding-bottom: 2rem;
  //padding-bottom: 7.312rem;

  display: flex;
  flex-direction: column;

  @media screen and (max-width: 992px) {
    min-height: unset;

    padding-bottom: 4rem;
  }
}

//1389px
.process-container {
  margin-top: 11vh;

  width: 79.59%;

  @media screen and (max-width: 1024px) {
    width: 91.66%;
  }

  @media screen and (max-width: 992px) {
    margin-top: 0;
  }
}

.process-row {
  display: flex;

  @media screen and (max-width: 992px) {
    flex-direction: column;
  }
}

.process-column {
  flex-shrink: 0;
}

.process-column--desc {
  width: 68.53%;

  @media screen and (max-width: 992px) {
    width: 100%;

    margin-bottom: 4rem;
  }
}

.process-column--info {
  margin-left: 2.55%;

  @media screen and (max-width: 992px) {
    margin-left: 0;
  }
}

.process-desc {

}

.process-desc__caption {
  font-size: 4rem;
  line-height: 1.2;
  font-weight: 900;
  color: var(--tectonic-white);

  margin-bottom: 3.4rem;

  @media screen and (min-width: 1921px) {
    br {
      display: none;
    }
  }

  @media screen and (max-width: 1024px) {
    br {
      display: block;
    }
  }
}

.process-desc__info {

}

.process-desc__list {
  margin: 0;
  padding: 0;
  list-style: none;
  //list-style-position: inside;

  &:not(:last-child) {
    margin-bottom: 0.9rem;
  }
}

.process-desc__item {
  position: relative;
  font-size: 1.5rem;
  font-weight: 400;
  color: var(--tectonic-white);
  padding-left: 2.31rem;

  &:not(:last-child) {
    margin-bottom: 0.9rem;
  }

  &:before {
    content: "";
    position: absolute;
    left: 0.93rem;
    top: 0.75rem;

    width: 0.31rem;
    height: 0.31rem;
    border-radius: 50%;

    flex-shrink: 0;

    background-color: var(--tectonic-white);
  }
}

.process-info {
  position: relative;

  @media screen and (max-width: 992px) {
    width: 19.875rem;
  }
}

.process-info__circle {
  height: 19.875rem;
  width: 19.875rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  padding-right: 3.2rem;
  padding-left: 3.2rem;

  border-radius: 50%;
  border: 2px solid var(--tectonic-green);

  font-size: 1.5rem;
  font-weight: 700;
  color: var(--tectonic-white);
}

.process-info__circle--top {
  margin-bottom: -5.7rem;

  padding-top: 3.7rem;
}

.process-info__circle--bottom {
  justify-content: flex-end;

  padding-bottom: 3.8rem;
}

.process-info__circle-info {
  text-align: center;
}

.process-info__circle-arrow {
  position: relative;
  width: 2.437rem;

  &::before {
    content: '';
    display: block;
    padding-top: 128.2%;
  }

  svg {
    max-height: 100%;
    max-width: 100%;

    fill: var(--tectonic-green);
  }
}


.process-info__circle-arrow-bottom {
  transform: scale(-1);

  margin-top: 1.125rem;

  order: 1;
}

.process-info__circle-arrow-top {
  margin-bottom: 1.125rem;
}

.process-info__title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  font-size: 1.5rem;
  font-weight: 400;
  color: var(--tectonic-white);
}
