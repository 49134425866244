.world {
  padding-top: 2.87rem;
  padding-bottom: 6.93rem;
}

.world-row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 1024px){
    flex-direction: column;
  }
}

.world-column {
  display: flex;
  flex-direction: column;
}

.world-column__img {
  width: 45.79%;

  @media screen and (max-width: 1024px){
    width: 100%;
  }
}

.world-column__image {
  width: 100%;
  position: relative;

  &:before {
    content: "";
    display: block;
    padding-top: 66.62%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}


.world-column__info {
  width: 47.89%;
  margin-top: 5.2rem;

  @media screen and (max-width: 1024px){
    width: 100%;
  }
}

.world-column__text {
  font-size: 1.5rem;
  line-height: 1.5;
  color: var(--tectonic-black-light);
  font-weight: 300;

  span{
    font-weight: 700;
  }

  &:not(:last-child){
    margin-bottom: 1.56rem;
  }
}


