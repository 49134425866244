.login-popup {
  position: relative;
  z-index: 1;

  margin: auto;

  display: flex;
  flex-direction: column;

  padding: 0.93rem;

  min-width: 31.25rem;

  background-color: var(--tectonic-black-light);

  border-radius: 1.875rem;
  border: 1px solid rgba(0, 0, 0, 0.2);

  @media screen and (max-width: 480px) {
    min-width: unset;

    width: 87%;
  }
}

.login-popup__header {
  padding: 1rem;

  font-size: 1.875rem;
  text-align: center;

  text-transform: uppercase;
  color: var(--tectonic-white);
}

.login-popup__form {
  padding-right: 1rem;
  padding-bottom: 1.2rem;
  padding-left: 1rem;
}

.login-popup__block {
  margin-bottom: 3rem;
}

.login-popup__label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.login-popup__label:not(:last-child) {
  margin-bottom: 1rem;
}

.login-popup__name {
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--tectonic-white);

  margin-bottom: 0.3rem;
}

.login-popup__input {
  appearance: none;
  width: 100%;
  border: none;

  min-width: unset;

  font-size: 1.125rem;
  font-weight: 400;
  color: var(--tectonic-white);

  border-radius: 0.25rem;

  padding-top: 0.375rem;
  padding-right: 0.75rem;
  padding-bottom: 0.375rem;
  padding-left: 0.75rem;

  background-color: var(--tectnoic-blue-dark);
}

.login-popup__input--error {
  border: 1px solid red;
}

.login-popup__accept {
  display: flex;
  justify-content: center;
}

.login-popup__accept-btn {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;

  font-size: 1.125rem;
  font-weight: 900;
  color: var(--tectonic-black);

  background-color: var(--tectonic-white);
}

.popup-close {
  appearance: none;
  border: none;
  padding: 0;

  background-color: rgba(255, 255, 255, 0.75);

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;

  position: absolute;
  top: -1.2rem;
  right: -1.2rem;
  z-index: 1;

  width: 2.5rem;

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  svg {
    max-height: 100%;
    max-width: 100%;
  }
}
