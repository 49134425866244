.infrastructure-hint {
  background-color: var(--tectonic-black-light);
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.infrastructure-hint-container {
  width: 66.97%;

  @media screen and (max-width: 1024px) {
    width: 91.66%;
  }
}

.infrastructure-hint__row {
  display: flex;
  flex-direction: column;
}

.infrastructure-hint__title {
  color: var(--tectonic-white);
  font-size: 1.75rem;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 6.25rem;
}

.infrastructure-hint__icons-list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
}

.infrastructure-hint__icons-item {
  display: flex;
  align-items: flex-start;

  &:not(:last-child) {
    margin-bottom: 4.3rem;
  }

  @media screen and (max-width: 1024px) {
    //flex-direction: column;
    //align-items: center;
  }
}

.infrastructure-hint__icons-item__icon {
  width: 5.37rem;
  position: relative;
  flex-shrink: 0;
  margin-right: 2.75rem;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  &:before {
    content: "";
    display: block;
    padding-top: 87.20%;
  }

  @media screen and (max-width: 1024px) {
    //margin-right: 0;
    //margin-bottom: 1.5rem;
    margin-right: 1.5rem;
  }
}

.infrastructure-hint__icons-item__text {
  font-size: 1.5rem;
  line-height: 1.5;
  font-weight: 300;
  color: var(--tectonic-white);

  div {
    font-weight: 700;
  }
}

.infrastructure-hint__icons-item__text-title {
  @media screen and (max-width: 1024px) {
    margin-bottom: 0.2rem;
  }
}
