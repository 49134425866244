.nav {
  display: flex;
  align-items: center;
}

.nav-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

.nav-item {
  @media (any-hover: hover) {
    &:not(.nav-item--dropdown) .nav-link:hover {
      color: var(--tectonic-green);

      &:before {
        width: 100%;
      }
    }
  }
}

.nav-item.nav-item--dropdown .nav-link {
  @media screen and (max-width: 1024px) {
    align-items: center;

    &::after {
      content: '+';
      display: inline-flex;
      font-size: 1.85rem;
      flex-shrink: 0;

      margin-left: 0.875rem;
    }
  }
}

.nav-item:not(:last-child) {
  margin-right: 3.12rem;

  @media screen and (max-width: 1080px) {
    margin-right: 2.5rem;
  }
}

.nav-item--dropdown {
  position: relative;

  @media screen and (min-width: 1025px) {
    &:hover .nav-dropdown {
      max-height: 16rem;
    }
  }


  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
}

.nav-link {
  appearance: none;
  border: none;
  padding-right: 0;
  padding-left: 0;
  background-color: transparent;
  position: relative;

  display: inline-flex;
  align-items: center;

  text-decoration: none;
  text-transform: uppercase;

  font-size: 1.12rem;
  font-weight: 700;
  line-height: 1.5;
  color: var(--tectonic-white);

  padding-top: 0.875rem;
  padding-bottom: 0.875rem;

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    height: 2px;
    width: 0;
    background-color: var(--tectonic-green);
    transition: all 400ms;

    @media screen and (max-width: 1024px) {
      content: none;
    }
  }
}

.nav-link--dropdown ~ .nav-dropdown {
  max-height: 16rem;
}

.nav-link__icon {
  position: relative;
  width: 1.125rem;
  flex-shrink: 0;

  display: inline-flex;

  margin-right: 0.5rem;

  &::before {
    content: '';
    display: block;
    padding-top: 120%;
  }

  svg {
    max-height: 100%;
    max-width: 100%;
    fill: var(--tectonic-white);
  }

  @media screen and (max-width: 1024px) {
    svg {
      fill: var(--tectonic-black);
    }
  }
}

.nav-link--active {
  color: var(--tectonic-green);

  &:hover {
    &:before {
      content: none;
    }
  }
}

.nav-dropdown {
  position: absolute;
  top: 3.43rem;
  left: 0;
  z-index: 1;

  max-height: 0;
  overflow: hidden;

  transition: 250ms;

  @media screen and (max-width: 1024px) {
    position: static;

    margin-right: 10px;
    margin-left: 10px;

    .nav-dropdown__link {
      width: 100%;
    }
  }
}

.nav-dropdown--footer {
  top: auto;
  bottom: 3.43rem;

  @media screen and (max-width: 1024px) {
    .nav-dropdown__link {
      font-size: 1rem;
    }
  }
}

.nav-dropdown__list {
  margin: 0;
  padding: 0;
  list-style: none;

  border-radius: 0.625rem;

  padding-top: 0.875rem;
  padding-bottom: 0.875rem;

  min-width: 8rem;

  border: 2px solid var(--tectonic-green);
  background-color: var(--tectonic-white);

  @media screen and (max-width: 1024px) {
    border: none;

    padding-top: 0;
    padding-bottom: 0;
  }
}

.nav-dropdown__link {
  appearance: none;
  border: none;
  background-color: transparent;
  display: flex;

  cursor: pointer;

  text-decoration: none;

  font-size: calc(13px + 0.25rem);
  color: var(--tectonic-black);

  transition: 250ms;

  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  white-space: nowrap;

  padding-right: 0.875rem;
  padding-left: 0.875rem;

  &:hover {
    color: var(--tectonic-green);
  }

  @media screen and (max-width: 1024px) {
    justify-content: center;
  }
}

.nav-dropdown__link--active {
  color: var(--tectonic-green);
}


