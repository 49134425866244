.advertising {
  position: relative;

  min-height: 200px;

  &::before {
    content: "";
    display: block;
    padding-top: 26.56%;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
