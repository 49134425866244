.energy-info{
  background-image: url("../../img/background/energy-info.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 40.37rem;
  padding-top: 1rem;
  padding-bottom: 1rem;

  position: relative;

  display: flex;
  align-items: center;

  .container{
    display: flex;
    flex-direction: column;
  }
}

.energy-info__bg{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(16, 25, 35, 0.75);
}

.energy-info__block{
  font-size: 1.5rem;
  font-weight: 300;
  color: var(--tectonic-white);
  z-index: 1;
  width: 49%;

  @media screen and (max-width: 1024px){
    width: 100%;
  }
}

.energy-info__text{
  span{
    font-weight: 700;
  }
}

.energy-info__text:not(:last-child){
  margin-bottom: 1.7rem;
}
