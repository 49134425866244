.property-mobility__extra {
  padding-bottom: 5.5rem;
  padding-top: 11rem;

  .property-info__desc {
    width: 55%;
  }
}

.mobility-info {
  padding-top: 5rem;
  padding-bottom: 3.2rem;
}

.mobility-info__row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 1024px) {
    flex-direction: column-reverse;
  }
}

.mobility-info__text {
  width: 53.38%;
  font-size: 1.5rem;
  color: var(--tectonic-black-light);
  font-weight: 300;

  span {
    font-weight: 700;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}


.mobility-info__text-row {
  line-height: 1.5;
  margin-bottom: 1.5rem;
}

.mobility-info__text-column__title {
  line-height: 1.5;
  margin-bottom: 0.87rem;
}

.mobility-info__text-column__row {
  line-height: 1.5;
  position: relative;
  padding-left: 2rem;

  &:not(:last-child) {
    margin-bottom: 0.75rem;
  }

  &:before {
    content: "";
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: var(--tectonic-black);
    top: 0.75rem;
    left: 0.75rem;
  }


}

.mobility-info__icon {
  width: 40.73%;
  position: relative;
  min-width: 200px;

  img {
    width: 100%;
    height: 100%;
  }

  &:before {
    content: "";
    display: block;
    padding-top: 59.27%;
  }

  @media screen and (max-width: 1024px) {
    margin-bottom: 1.5rem;
  }
}
